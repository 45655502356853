<template>
   <v-form ref="trade-in-record-form" @submit.prevent="formSubmit">
      <v-row>
         <v-col cols="12">
            <h3>Trade-in record</h3>
         </v-col>
         <v-col cols="12">
            <v-btn color="secondary" class="m-auto px-8" large @click.prevent="$router.go(-1)" :disabled="isFetching">Back</v-btn>
         </v-col>
         <v-col cols="12">
            <v-card>
               <v-card-text>
                  <v-row>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.date" label="Submit date" :readonly="true"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.user" label="User" :readonly="true"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="string" v-model="data.category" label="Category" :readonly="true"/>
                     </v-col>
                     <v-col md="6" cols="12">
                        <FormControl inputType="number" v-model="data.price" label="Price" :required="true"/>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col md="12" cols="12">
            <v-card>
               <v-card-title><h5>Receipt photo</h5></v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col md="12" cols="12">
                        <v-row>
                           <v-col md="12" cols="12">
                              <ImageBtnUpload v-model="data.receipt_photo" label="Receipt" :required="true" />
                           </v-col>
                           <v-col md="12" cols="12">
                              <ImageBtnUpload v-model="data.item_photo" label="Item" :required="true" />
                           </v-col>
                        </v-row>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
         <v-col cols="12">
            <v-btn color="primary" class="px-8 form-btn" large :disabled="isFetching" type="submit">Save</v-btn>
            <!-- <FormDeleteBtn :removeAction="removeItem" /> -->
         </v-col>
      </v-row>
   </v-form>
</template>

<script>
import ImageBtnUpload from '@/components/form/ImageBtnUpload'
import FormControl from '@/components/form/FormControl'
import FormDisplay from '@/components/form/FormDisplay'
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
   name: 'TradeInRecordDetail',
   components: {
      FormControl,
      FormDisplay,
      ImageBtnUpload
   },
   data() {
      return {
         data: {
            id: _.toNumber(this.$route.params.id),
            date: '',
            user: 0,
            category: 0,
            price: 0,
            item_photo: '',
            receipt_photo: '',
         },
         dataOptions: {
            category: [],
         }
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest', 'showLoader'], 'request'),
      ...mapActions(['setDialogMessage', 'setShowDialog']),
      async initData() {
         const resp = await this.sendRequest({ type: 'get_trade_in_by_id', data: { id: this.data.id }, options: { noStatusSet: true }})
         if (!resp) {
            this.showLoader(false)
            return
         }
         const [userResp, categoryResp] = await Promise.all([
            this.sendRequest({ type: 'get_user_by_id', data: { id: resp.data.user_id }, options: { noStatusSet: true }}),
            this.sendRequest({ type: 'get_trade_in_category_by_id', data: { id: resp.data.category_id }, options: { noStatusSet: true }}),
         ])
         this.showLoader(false)
         this.data = {
            ...this.data,
            date: moment(resp.data.create_date).format('YYYY-MM-DD HH:mm:ss'),
            user: userResp.data.username,
            category: categoryResp.data.title,
            price: resp.data.price,
            item_photo: resp.data.item_photo,
            receipt_photo: resp.data.receipt_photo,
         }
      },
      async formSubmit() {
         console.log('save')
         const isValid = this.$refs['trade-in-record-form'].validate()
         if (isValid) {
            const resp = await this.updateItem()
            this.setDialogMessage({ 
               message: resp ? 'Successful' : 'Failed', 
               isError: !resp, 
               returnLink: (this.data.id === undefined ? { name: 'redeemRecord' } : null )
            })
            this.setShowDialog(true)
         }
      },
      async updateItem() {
         console.log('update')
         const formdata = {
            data_type: 'trade_in_record_data',
            id: this.data.id,
            // category_id: this.data.category,
            // user_id: _.toNumber(this.data.user),
            price: _.toNumber(this.data.price),
            item_photo: this.data.item_photo,
            receipt_photo: this.data.receipt_photo,
         }
         return await this.sendRequest({ type: 'update_trade_in', data: formdata })
      },
   },
   async mounted() {
      await this.initData()
   }
}
</script>
